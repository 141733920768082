import useAppContext from 'lib/hooks/useAppContext';
import { useEffect } from 'react';
import { asyncTrack } from './analytics-utilities';

/**
 * Additional props to record in event.
 * Can be a function that returns props to lazily call like in useState.
 */
type AdditionalProps =
  | Record<string, unknown>
  | (() => Record<string, unknown>);

/**
 * Track Effect for PAGEVIEW.
 * (Won't track valid shallow renders)
 * (Will log multiple times in development because of fast refresh)
 */
export default function useTrackPageViewEffect(
  additionalProps?: AdditionalProps,
) {
  const { pathname, deviceType } = useAppContext();

  return useEffect(() => {
    const props =
      typeof additionalProps === 'function'
        ? additionalProps()
        : additionalProps;
    const mixpanelProps = {
      location: pathname,
      device_type: deviceType,
      ...props,
    };
    asyncTrack('PAGEVIEW', mixpanelProps);
    // Additional props is removed from deps since we don't want to trigger for mistakes
    //  with memoizing nor catch shallow routing re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
