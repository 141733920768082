import { useEffect } from 'react';
import { PianoEvent } from '../types';

export interface PianoConfig {
  eventHandlers?: Array<((e: PianoEvent) => void) | undefined>;
  postHandlers?: boolean;
  tags?: Array<string>;
  segment: any;
}

export default function usePiano({
  eventHandlers = [],
  postHandlers,
  tags,
  segment,
}: PianoConfig) {
  useEffect(() => {
    if (!segment) return;
    window.tp = window.tp || [];

    // set Tags
    if (tags) window.tp.push(['setTags', tags]);

    // set userSegment
    if (segment) {
      window.tp.push(['setCustomVariable', 'userSegment', segment]);
    }

    //set Handlers
    if (postHandlers) {
      eventHandlers?.forEach((handler) => {
        if (handler) {
          window.tp.push([
            'addHandler',
            'checkoutCustomEvent',
            (e: PianoEvent) => handler(e),
          ]);
        }
      });
    }
  }, [tags, eventHandlers, segment, postHandlers]);

  const initPiano = () => {
    window.tp.push([
      'init',
      function initExperience() {
        window.tp.experience.execute();
      },
    ]);
  };

  return initPiano;
}
