import Link from '@mise/partials/Links/Link/Link';
import styles from './StandardDocCard.module.scss';
import { getImageUrl, baseImageConfig } from 'lib/cloudinary';
import { MixpanelData } from 'mise/src/types/analytics';

export interface StandardDocCardProps {
  cloudinaryId: string;
  headline: string;
  link: string;
  mixpanelData?: MixpanelData;
}

const imgConfig = {
  mobile: {
    ...baseImageConfig,
    width: 182,
    height: 182,
  },
  tablet: {
    ...baseImageConfig,
    width: 274,
    height: 274,
  },
  desktop: {
    ...baseImageConfig,
    width: 274,
    height: 274,
  },
};

const StandardDocCard: React.FC<StandardDocCardProps> = ({
  cloudinaryId,
  headline,
  link,
  mixpanelData,
}) => {
  return (
    <Link
      className={styles.standardDocCardWrapper}
      path={link}
      mixpanelData={mixpanelData}
      ariaLabel={headline}
    >
      <div aria-hidden={true}>
        <picture className={styles.standardDocCardImage}>
          <source
            media="(min-width: 1136px)"
            srcSet={getImageUrl(cloudinaryId, imgConfig.desktop)}
          />
          <source
            media="(min-width: 768px)"
            srcSet={getImageUrl(cloudinaryId, imgConfig.tablet)}
          />
          <img
            className={styles.image}
            alt={headline}
            aria-hidden={true}
            src={getImageUrl(cloudinaryId, imgConfig.mobile)}
            loading="lazy"
          />
        </picture>
      </div>
      <div>
        <h3 aria-hidden={true} className={styles.standardDocCardHeadline}>
          {headline}
        </h3>
      </div>
    </Link>
  );
};

export default StandardDocCard;
