import { useState, useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UserSegment } from './useUserState';
import utils from 'lib/utils';
import useUserState from 'lib/hooks/useUserState';

/**
 *
 * @param {*} isHatAudienceCondition
 * @returns {{
 *   user: any;
 *   segment: UserSegment;
 *   isHatAudience: boolean;
 * }}
 */
const useMarketingHat = (
  isHatAudienceCondition = ['anonymous', 'registrant'],
) => {
  const [user, setUser] = useState(null);
  const [segment, setSegment] = useState(null);
  const userData = useUserState();

  useEffect(() => {
    setUser(userData);
    const { cancelled_memberships, segment } = userData ?? {};

    if (segment === 'cancelled_other') {
      const cancelledSchoolMembership = cancelled_memberships.every(
        (val) => !['atk', 'cio', 'cco'].includes(val),
      );
      if (cancelledSchoolMembership) {
        setSegment('registrant');
      } else {
        setSegment('former');
      }
    } else {
      setSegment(
        userData &&
          userData?.segment &&
          utils.getUserSegment(userData?.segment),
      );
    }
  }, [userData]);

  return {
    user,
    segment,
    isHatAudience: isHatAudienceCondition.includes(segment),
  };
};

export default useMarketingHat;
