/**
 * Delay and resume link navigations. Often used in mixpanel callbacks.
 * @param {React.MouseEvent<HTMLAnchorElement | HTMLElement>} clickEvent
 * @param {string} [manualHref] optional link value if onClick element doesn't have an href.
 * @returns {() => void} callback to resume the navigation.
 */
export default function delayLinkClick(clickEvent, manualHref) {
  clickEvent.preventDefault();

  return function callback() {
    const href = manualHref ?? manualHref?.currentTarget?.href;

    if (!href) return;

    // command click opens in a new tab
    if (clickEvent.metaKey) {
      window.open(href, '_blank');
      return;
    }

    document.location.href = href;
  };
}
