/** need to split this out since importing hooks/mixpanel errors legacy code that can't ssr :(  */

import type { DeviceType } from '../useAppContext';

type TrackedPartnerLinkProps = {
  asin: string;
  price: string;
  recommendationStatus?: string | null;
  reviewable: string;
  location?: string;
  affiliateTag?: string | null;
  affiliateUrl?: string;
};

/** Buy now event mixpanel data */
export function trackedParterLink({
  asin,
  price,
  recommendationStatus,
  reviewable,
  location,
  affiliateTag,
  affiliateUrl,
}: TrackedPartnerLinkProps) {
  return {
    'data-asin': asin,
    'data-price': price,
    'data-reviewable': reviewable,
    'data-recommendation-status': recommendationStatus,
    'data-location': location,
    'data-affiliate-tag': affiliateTag,
    'data-affiliate-url': affiliateUrl,
  };
}

export function mxPropsPartnerLink(
  partnerLink: HTMLElement,
  deviceType?: DeviceType,
) {
  const {
    asin,
    price,
    reviewable,
    recommendationStatus,
    title,
    location,
    reviewSet,
    affiliateTag,
    affiliateUrl,
    retailer,
    pathname,
  } = partnerLink?.dataset || {};
  return {
    asin,
    price,
    reviewable,
    recommendationStatus,
    deviceType,
    title,
    location,
    reviewSet,
    affiliateTag,
    affiliateUrl,
    retailer,
    pathname,
  };
}

/**
 * type - Section type tracked by page click as `type` in mixpanel.
 * trackButtons - Enable section to track button clicks for sections that aren't tracking links.
 * Keep this disabled in sections with buttons that trigger their own mixpanel events.
 * @returns {Record<HTMLElement['dataset'], string>} dataset attributes. use in react intrinsic
 *  elements and styled components with {...trackedSection({ type })}.
 */
export function trackedSection({
  type,
  trackButtons,
}: {
  type: string;
  trackButtons?: boolean;
}) {
  return {
    'data-section-type': type,
    'data-track-buttons': trackButtons ? 'true' : '',
  };
}

export function trackedDocument({
  documentTitle,
  documentType,
}: {
  documentTitle: string;
  documentType: string;
}) {
  return { 'data-title': documentTitle, 'data-type': documentType };
}

export function mxPropsDocument(
  dataWrapper: HTMLElement,
  deviceType: DeviceType,
  sectionType: string,
) {
  const { title: documentTitle, type: documentType } =
    dataWrapper?.dataset || {};
  return { documentTitle, documentType, deviceType, type: sectionType };
}
