import { useEffect } from 'react';

/**
 * Pass a state callback ref for first argument
 * ```typescript
 * const [ref, setRef] = useState<HTMLDivElement | null>(null);
 * ```
 * Prevent drag and text selection and hide scrollbars with mixin scroll-area
 * ```scss
 * .scrolling-element {
 *   @ include scroll-area;
 * }
 * ```
 */
export default function useScrollDrag(slider: HTMLDivElement | null) {
  useEffect(() => {
    if (!slider) return;
    const s = {
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      shouldPrevent: false,
    };

    slider.addEventListener('mousedown', (e) => {
      s.isDown = true;
      s.startX = e.pageX;
      s.scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener('mouseleave', () => {
      s.isDown = false;
    });

    slider.addEventListener('mouseup', () => {
      if (s.shouldPrevent) {
        s.shouldPrevent = false;
        slider.addEventListener('click', (ev) => ev.preventDefault(), {
          capture: true,
          once: true,
        });
      }
      s.isDown = false;
    });

    slider.addEventListener('mousemove', (e) => {
      if (!s.isDown) {
        s.shouldPrevent = false;
        return;
      }
      const xDiff = e.pageX - s.startX;
      if (Math.abs(xDiff) >= 25) {
        s.shouldPrevent = true;
      }
      slider.scrollLeft = s.scrollLeft - xDiff;
    });
  }, [slider]);
}
