/**
 * Flagging we can probably move this higher up into
 * a generic folder at some point
 */

import { PropsWithChildren, useState } from 'react';
import useScrollButtons from './useScrollButtons';
import useScrollDrag from './useScrollDrag';
import styles from './ScrollCarousel.module.scss';
import cx from 'classnames';

export default function ScrollCarousel({
  title,
  href,
  children,
  id,
  headingId,
  className,
  headerClassName,
  carouselAreaId,
  previousButtonLabel,
  nextButtonLabel,
}: PropsWithChildren<{
  title: string;
  href?: string;
  twoCards?: boolean;
  id?: string;
  headingId?: string;
  className?: string;
  headerClassName?: string;
  carouselAreaId?: string;
  previousButtonLabel?: string;
  nextButtonLabel?: string;
}>) {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  useScrollDrag(ref);
  const { onClickStart, onClickEnd } = useScrollButtons(ref, -16);

  return (
    <div className={styles.standardCarousel}>
      <div className={cx(styles.header, styles[`${headerClassName}`])}>
        <div
          className={cx(
            'scroll-carousel-title',
            styles.title,
            styles[`${className}`],
          )}
        >
          {href ? (
            <a className="toc-heading" id={headingId} href={href}>
              {title} <ChevronRight />
            </a>
          ) : (
            <h3
              aria-label={title}
              className="toc-heading"
              id={headingId}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          )}
        </div>
        <div className={styles.buttons}>
          <button
            onClick={onClickStart}
            aria-label={previousButtonLabel ?? 'Previous'}
          >
            <ArrowLeft />
          </button>
          <button onClick={onClickEnd} aria-label={nextButtonLabel ?? 'Next'}>
            <ArrowRight />
          </button>
        </div>
      </div>
      <div
        id={carouselAreaId ? carouselAreaId : undefined}
        className={cx(styles.carouselArea, styles[`${id}`])}
        ref={setRef}
      >
        {children}
      </div>
    </div>
  );
}

export function ChevronRight() {
  return (
    <svg width="8" height="15" viewBox="0 0 8 15" fill="none">
      <path
        d="M1 1.5L6 7.5L1 13.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

function ArrowLeft() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <circle cx="16" cy="16" r="15.5" stroke="currentColor" />
      <path
        d="M18.4062 10L12.4062 16L18.4062 22"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ArrowRight() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <circle cx="16" cy="16" r="15.5" stroke="currentColor" />
      <path
        d="M13.4062 22L19.4063 16L13.4063 10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
