import { UrlObject } from 'url';

import MiseComponent from '../../../../types/miseComponent';

export interface LinkProps extends MiseComponent {
  children: React.ReactNode;
  path?: string | (UrlObject & string);
  target?: '_blank' | '_self' | '_parent' | '_top';
}

// starting collection of paths where NextLink will return error
// until page is refreshed
// const nonNextPaths = ['/user']

export const Link = ({
  className,
  events,
  children,
  mixpanelData,
  path = '/',
  target = '_self',
  ariaLabel,
}: LinkProps) => {
  // TODO: Temp removal until Next Router implemented properly.
  // if (path.startsWith('/') && !nonNextPaths.includes(path)) {
  //   return (
  //     <NextLink
  //       href={path}
  //       passHref
  //     >
  //       {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
  //       <a className={className}>
  //         {children}
  //       </a>
  //     </NextLink>
  //   )
  // } else {
  return (
    <a
      className={className}
      href={path}
      target={target}
      aria-label={ariaLabel}
      {...mixpanelData}
      onClick={events?.onClick}
    >
      {children}
    </a>
  );
  // }
};

export default Link;
