import { useCallback } from 'react';

export default function useScrollButtons(
  element: HTMLDivElement | null,
  size: number,
) {
  const onClickStart = useCallback(() => {
    if (!element) return;
    element.scrollTo({
      left: element.scrollLeft + size - element.offsetWidth,
      behavior: 'smooth',
    });
  }, [element, size]);

  const onClickEnd = useCallback(() => {
    if (!element) return;
    element.scrollTo({
      left: element.scrollLeft - size + element.offsetWidth,
      behavior: 'smooth',
    });
  }, [element, size]);

  return { onClickStart, onClickEnd };
}
