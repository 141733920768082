import utils from 'lib/utils';
import analytics from 'lib/analytics';
import events from 'lib/analytics/mixpanel/events';
import has from 'lodash.has';

/**
 * Console log utility for logging analytics environment option.
 * add NEXT_PUBLIC_ANALYTICS_LOGGING=true to .env.local for logging, not git watched env.
 * filter analyticsLog in console to see only these usage logs.
 * (intentionally not service library logs).
 * (does not log super props, intended to help devs add new tracking not debug mixpanel issues)
 * @param {string} messageOrEvent mixpanel event name or generic message
 * @param {?Record<string, unknown>} [properties={}] optional object of additional properties
 * @return {void}
 */
export function analyticsLog(messageOrEvent, properties = {}) {
  if (process.env.NEXT_PUBLIC_ANALYTICS_LOGGING) {
    // eslint-disable-next-line no-console
    console.log({
      analyticsLog: messageOrEvent,
      ...utils.mixpanel.formatProperties(properties),
    });
  }
}

/**
 * Wrapper for analytics.track with promises and env logging.
 * add NEXT_PUBLIC_ANALYTICS_LOGGING=true to .env.local for logging, not git watched env.
 * @param {keyof events} eventName event name from list of accepted events
 * @param {Record<string, unknown>} properties mixpanel properties (formatted utility in asyncTrack)
 * @returns {Promise<any>} promisified callback of analytics track
 */
export function asyncTrack(eventName, properties = {}) {
  // Only resolve to never block thenable chains if mixpanel fails.
  return new Promise((resolve) => {
    if (!has(events, eventName)) {
      /**
       * lib/analytics/mixpanel/index.js will skip the callback
       *  if the event name isn't in the approved list!
       */
      analyticsLog(
        `[asyncTrack error] event: ${eventName} not in approved list of events`,
      );
      resolve();
      return;
    }
    try {
      analyticsLog(eventName, properties);
      analytics.track(
        eventName,
        utils.mixpanel.formatProperties(properties),
        resolve,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      analyticsLog(
        `[asyncTrack error] event: ${eventName} with properties: ${properties} error: ${error}`,
      );
      resolve();
    }
  });
}
